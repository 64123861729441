<template>
    <v-container grid-list-md text-xs-center>
        <v-layout column>
            <v-flex>
                <!--<Breadcrumbs></Breadcrumbs>-->
            </v-flex>
            <v-flex>
                <transition name="slide" mode="out-in">
                    <router-view></router-view>
                </transition>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    // import Breadcrumbs from '@/components/Breadcrumbs.vue'

    export default {
        name: "profile",
        components: {
            // Breadcrumbs
        },
        data() {
            return {}
        }
    }
</script>

